import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import 'antd/dist/antd.min.css'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Sanctum } from 'react-sanctum'

const sanctumConfig = {
  apiUrl: process.env.REACT_APP_API_URL,
  csrfCookieRoute: 'sanctum/csrf-cookie',
  signInRoute: 'login',
  signOutRoute: 'logout',
  userObjectRoute: 'api/v1/user',
}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Sanctum config={sanctumConfig} checkOnInit={false}>
        <App />
      </Sanctum>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
