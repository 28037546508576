import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSanctum } from 'react-sanctum'
import { Form, Input, Checkbox, Button } from 'antd'

const LoginForm = () => {
  const { signIn } = useSanctum()
  const navigate = useNavigate()

  const handleLogin = (data) => {
    if (!data.email || !data.password) {
      return
    }

    signIn(data.email, data.password, data.remember)
      .then(() => {
        localStorage.setItem('is_user_logged_in', 'true')
        navigate('/', { replace: true })
      })
      .catch(() => window.alert('Incorrect email or password'))
  }

  return (
    <Form
      name='basic'
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      initialValues={{ remember: true }}
      onFinish={handleLogin}
      autoComplete='off'
    >
      <Form.Item
        label='Email'
        name='email'
        rules={[{ required: true, message: 'Please input your username!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label='Password'
        name='password'
        rules={[{ required: true, message: 'Please input your password!' }]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        name='remember'
        valuePropName='checked'
        wrapperCol={{ offset: 8, span: 16 }}
      >
        <Checkbox>Remember me</Checkbox>
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type='primary' htmlType='submit'>
          Submit
        </Button>
      </Form.Item>
    </Form>
  )
}

export default LoginForm
