import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Button from '../components/common/Button'
import { useNavigate } from 'react-router-dom'
import { FaTrash } from 'react-icons/fa'
import Api from '../services/Api'

const TextCenter = styled.span`
  text-align: center;
`

const SaleCardActionsOverlayWrapper = styled.div`
  width: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #9ed19b;
  transition: 0.3s;
  position: relative;
  overflow: hidden;
  text-overflow: clip;
  cursor: pointer;
`

const SaleCardActionsOverlay = styled.div`
  width: 150px;
  position: absolute;
  text-align: center;
`

const SaleCard = styled.div`
  position: relative;
  width: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  transition: 0.2s;
  background-color: #fff;
  margin-bottom: 12px;
  display: flex;

  &:hover {
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.2);
  }

  &:hover ${SaleCardActionsOverlayWrapper} {
    width: 150px;
  }

  &:hover ${SaleCardActionsOverlay} {
    display: block;
  }
`
const SaleCardContents = styled.div`
  padding: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
  width: 100%;

  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 10px;
  }
`

const SaleDescription = styled.div`
  width: 100%;
  flex: 1;
  padding-right: 20px;
  @media (max-width: 1024px) {
    padding-bottom: 4px;
    border-bottom: 1px solid #ddd;
  }
`

const SaleOptionsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
`

const SaleButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

const Home = () => {
  const [sales, setSales] = useState([])
  const [loading, setLoading] = useState(false)
  let navigate = useNavigate()

  const fetchSales = () => {
    setLoading(true)
    Api.get('/api/v1/sales', {
      params: {
        per_page: 50,
        sort_by: 'created_at',
        sort_order: 'desc',
      },
    }).then((res) => {
      setSales(res.data.data)
      setLoading(false)
    })
  }

  const onSaleDelete = (sale) => {
    sale.deleting = true
    setSales([...sales])

    Api.delete(`/api/v1/sales/${sale.id}`)
      .then(() => {
        setSales(sales.filter((s) => s.id !== sale.id))
      })
      .catch((err) => {
        console.error(err)
        sale.deleting = false
        setSales([...sales])
        alert('Error deleting sale.')
      })
  }

  const handlePreventLossChange = (evt, sale) => {
    if (loading) return
    if (['starting', 'ongoing', 'closing'].includes(sale.status)) return

    sale.prevent_loss = evt.target.checked
    setLoading(true)

    Api.put(`/api/v1/sales/${sale.id}`, {
      prevent_loss: evt.target.checked,
    })
      .then(() => {
        setSales([...sales])
        setLoading(false)
      })
      .catch((err) => {
        alert('could not update status')
        setLoading(false)
      })
  }

  const startSale = (sale) => {
    if (loading) return

    const backupStatus = sale.status
    sale.status = 'starting'
    setSales([...sales])

    Api.post(`/api/v1/sales/${sale.id}/start`)
      .then(() => {
        const pollingId = setInterval(() => {
          Api.get(`/api/v1/sales/${sale.id}`).then((res) => {
            if (res.data.status !== 'starting') {
              sale.status = res.data.status
              setSales([...sales])
              clearInterval(pollingId)
            }
          })
        }, 2000)
      })
      .catch((err) => {
        sale.status = backupStatus
        setSales([...sales])
        alert('error starting sale')
      })
  }

  const endSale = (sale) => {
    if (loading) return

    const backupStatus = sale.status
    sale.status = 'closing'
    setSales([...sales])

    Api.post(`/api/v1/sales/${sale.id}/end`)
      .then(() => {
        const pollingId = setInterval(() => {
          Api.get(`/api/v1/sales/${sale.id}`).then((res) => {
            if (res.data.status !== 'closing') {
              sale.status = res.data.status
              setSales([...sales])
              clearInterval(pollingId)
            }
          })
        }, 2000)
      })
      .catch((err) => {
        sale.status = backupStatus
        setSales([...sales])
        alert('error ending sale')
      })
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(fetchSales, [])

  return (
    <>
      <div>
        <Button
          text='Create new sale'
          color='green'
          style={{ marginBottom: 10 }}
          onClick={() => navigate('/sales/create')}
        ></Button>

        {sales.map((sale) => (
          <SaleCard key={sale.id}>
            <SaleCardContents>
              <SaleDescription>
                <div>
                  <small>
                    {new Date(sale.created_at).toLocaleString('ja-JP')}
                  </small>
                </div>
                {sale.description}
              </SaleDescription>
              <SaleOptionsWrapper>
                <TextCenter>
                  Discount:
                  <br /> {sale.discount_rate}%
                </TextCenter>
                <TextCenter>
                  <div>Prevent Loss</div>
                  <input
                    type='checkbox'
                    checked={sale.prevent_loss}
                    disabled={['starting', 'ongoing', 'closing'].includes(
                      sale.status
                    )}
                    onChange={(e) => handlePreventLossChange(e, sale)}
                  />
                </TextCenter>
                <SaleButtonsWrapper>
                  <Button
                    color='green'
                    onClick={() => navigate(`/sales/${sale.id}/products`)}
                  >
                    Products
                  </Button>
                  {['inactive', 'starting', 'ongoing', 'ended'].includes(
                    sale.status
                  ) && (
                    <Button
                      textColor='#fff'
                      text={
                        sale.status === 'ended'
                          ? 'Restart (ended)'
                          : sale.status === 'ongoing'
                          ? 'Ongoing'
                          : 'Start Sale'
                      }
                      loading={sale.status === 'starting'}
                      disabled={['starting', 'ongoing'].includes(sale.status)}
                      onClick={() => startSale(sale)}
                    />
                  )}
                  {['ongoing', 'closing'].includes(sale.status) && (
                    <Button
                      color='#d71616'
                      text='End Sale'
                      loading={sale.status === 'closing'}
                      onClick={() => endSale(sale)}
                    />
                  )}
                  {['inactive', 'ended'].includes(sale.status) && (
                    <Button
                      icon={<FaTrash></FaTrash>}
                      color='#d71616'
                      text='Delete'
                      onClick={() => onSaleDelete(sale)}
                      loading={sale.deleting === true}
                    />
                  )}
                </SaleButtonsWrapper>
              </SaleOptionsWrapper>
            </SaleCardContents>
          </SaleCard>
        ))}
      </div>
    </>
  )
}

export default Home
