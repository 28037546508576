import axios from 'axios'

const Api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
})

Api.interceptors.response.use(
  (res) => res,
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem('is_user_logged_in')
      window.location.reload()
      return Promise.reject()
    }

    return Promise.reject(error)
  }
)

export default Api
