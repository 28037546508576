import React, { useEffect, useState } from 'react'
import Flex from '../components/common/Flex'
import Notification from '../components/Notification'
import Api from '../services/Api'

const Notifications = () => {
  const [notifications, setNotifications] = useState([])

  useEffect(() => {
    Api.get('/api/v1/notifications').then((res) => {
      setNotifications(res.data.data)
    })
  }, [])

  return (
    <div>
      <Flex flexDirection='column' flexGap={10}>
        {notifications.length > 0 &&
          notifications.map((notification) => (
            <Notification
              key={notification.id}
              notification={notification}
            ></Notification>
          ))}
      </Flex>
    </div>
  )
}

export default Notifications
