import React from 'react'
import LoginForm from '../components/auth/LoginForm'
import styled from 'styled-components'

const Container = styled.div`
  max-width: 500px;
  height: auto;
  margin: 0 auto;
`

const LoginFormWrapper = styled.div`
  border-radius: 10px;
  box-shadow: 0 2px 12px 4px rgba(0, 0, 0, 0.1);
  padding: 30px;
  margin: auto;
  background: #fff;
  position: fixed;
  width: 500px;
  top: 30%;
`

const Login = () => {
  return (
    <div>
      <Container>
        <LoginFormWrapper>
          <LoginForm />
        </LoginFormWrapper>
      </Container>
    </div>
  )
}

export default Login
