import React, { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Table, Input, Button, Image } from 'antd'
import Api from '../services/Api'

const ProductsTable = ({
  disableQuery,
  perPage = 25,
  selectedActionText,
  selectedActionOnClick = () => {},
}) => {
  let [query, setQuery] = useSearchParams()
  const [loading, setLoading] = useState(true)
  const [products, setProducts] = useState([])
  const [keywords, setKeywords] = useState(query.get('keywords') || '')
  const [selectedItems, setSelectedItems] = useState([])
  const [itemsPerPage, setItemsPerPage] = useState(
    parseInt(query.get('per_page')) || perPage
  )
  const [currentPage, setCurrentPage] = useState(
    parseInt(query.get('page')) || 1
  )
  const [totalItems, setTotalItems] = useState(0)
  const { Search } = Input

  const fetchProducts = () => {
    setLoading(true)
    Api.get('/api/v1/products', {
      params: {
        keywords,
        page: currentPage,
        per_page: itemsPerPage,
      },
    }).then((res) => {
      setProducts(res.data.data)
      setTotalItems(res.data.meta.total)
      if (!disableQuery) {
        setQuery({
          ...Object.fromEntries(query.entries()),
          keywords,
          page: currentPage,
          per_page: itemsPerPage,
        })
      }
      setLoading(false)
    })
  }

  const searchProducts = () => {
    if (currentPage !== 1) {
      //this will trigger rerender and fetch products
      setCurrentPage(1)
    } else {
      fetchProducts()
    }
  }

  const handlePaginationChange = (page, perPage) => {
    setCurrentPage(page)
    setItemsPerPage(perPage)
    if (perPage < products.length) {
      //slice products array to match page size before rerender to avoid table warning
      setProducts(products.slice(0, perPage))
    }
    window.scrollTo({ top: 0, behavior: 'auto' })
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(fetchProducts, [currentPage, itemsPerPage])

  return (
    <>
      <div style={{ marginBottom: 8 }}>
        <Search
          placeholder='Search Products...'
          allowClear
          onChange={(e) => setKeywords(e.target.value)}
          onSearch={searchProducts}
        />
      </div>

      <div style={{ marginBottom: 8 }}>
        {selectedItems.length > 0 && (
          <>
            {selectedActionText && (
              <Button
                type='primary'
                onClick={() => selectedActionOnClick(selectedItems)}
              >
                {selectedActionText || 'Action'}
              </Button>
            )}
            <span style={{ marginLeft: 8 }}>
              Selected: {selectedItems.length} products
            </span>
          </>
        )}
      </div>

      <Table
        rowKey='id'
        dataSource={products}
        bordered
        columns={[
          {
            title: '',
            dataIndex: 'thumbnail_url',
            align: 'center',
            width: 60,
            render: (url) =>
              !url ? (
                'No Image'
              ) : (
                <Image
                  src={url}
                  style={{
                    width: '40px',
                    height: '40px',
                    objectFit: 'contain',
                  }}
                />
              ),
          },
          {
            title: 'Title',
            dataIndex: 'title',
            render: (title) => title,
          },
          { title: 'SKU', dataIndex: 'sku', render: (sku) => sku },
          {
            title: 'Price',
            dataIndex: 'price',
            render: (price) =>
              new Intl.NumberFormat('ja-JP', {
                style: 'currency',
                currency: 'JPY',
              }).format(price),
          },
          {
            title: 'Retail Price',
            dataIndex: 'retail_price',
            render: (retail_price) =>
              new Intl.NumberFormat('ja-JP', {
                style: 'currency',
                currency: 'JPY',
              }).format(retail_price),
          },
        ]}
        rowSelection={{
          onChange: (selectedRowKeys, selectedRows) => {
            setSelectedItems(selectedRows)
          },
          preserveSelectedRowKeys: true,
        }}
        pagination={{
          defaultPageSize: 25,
          pageSize: itemsPerPage,
          total: totalItems,
          pageSizeOptions: [10, 15, 25, 50, 100],
          onChange: handlePaginationChange,
        }}
        loading={loading}
      />
    </>
  )
}

export default ProductsTable
