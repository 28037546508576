import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSanctum } from 'react-sanctum'

const Logout = () => {
  const [status, setStatus] = useState('Logging Out...')
  const { signOut } = useSanctum()
  const navigate = useNavigate()

  useEffect(() => {
    signOut().then(() => {
      localStorage.removeItem('is_user_logged_in')
      setStatus('You successfully logged out.')
      navigate('/login')
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <div>{status}</div>
}

export default Logout
