/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import Api from '../services/Api'
import {
  Table,
  Image,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Typography,
  Upload,
  message,
  Tooltip,
} from 'antd'
import { useParams } from 'react-router'
import Button from '../components/common/Button'
import Flex from '../components/common/Flex'
import FlexItem from '../components/common/FlexItem'
import ProductsTable from '../components/ProductsTable'
import { FaQuestionCircle, FaUpload } from 'react-icons/fa'

const SaleProducts = () => {
  const [products, setProducts] = useState([])
  const [sale, setSale] = useState(null)
  const [productsLoading, setProductsLoading] = useState(false)
  const [addingProducts, setAddingProducts] = useState(false)
  const [keywords, setKeywords] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [totalItems, setTotalItems] = useState(0)
  const { Search } = Input

  let { id } = useParams()
  const fetchSale = async () => {
    const { data } = await Api.get(`/api/v1/sales/${id}`)
    setSale(data)
  }
  const fetchSaleProducts = async () => {
    setProductsLoading(true)
    const { data } = await Api.get(`/api/v1/sales/${id}/products`, {
      params: {
        include: 'catalog_details',
        sort_by: 'created_at',
        sort_order: 'desc',
        keywords,
        page: currentPage,
        per_page: itemsPerPage,
      },
    })
    setProducts(data.data)
    setTotalItems(data.meta.total)
    setProductsLoading(false)
  }
  const deleteSaleProduct = async (sale_product) => {
    await Api.delete(`/api/v1/sales/${id}/products/${sale_product.id}`)
    fetchSaleProducts()
  }

  const addProductsToSale = async (products) => {
    await Api.post(`/api/v1/sales/${id}/products`, {
      product_ids: products.map((p) => p.id),
    })
    fetchSaleProducts()
  }

  const updatePreventLoss = async (evt, product) => {
    evt.target.disabled = true
    product.prevent_loss = evt.target.checked

    try {
      await Api.put(`/api/v1/sales/${id}/products/${product.id}`, {
        prevent_loss: evt.target.checked,
      })
      setProducts([...products])
      evt.target.disabled = false
    } catch (err) {
      console.error(err)
      product.prevent_loss = evt.target.checked
      setProducts([...products])
      evt.target.disabled = false
      alert('error updating')
    }
  }

  const handlePaginationChange = (page, perPage) => {
    setCurrentPage(page)
    setItemsPerPage(perPage)
    if (perPage < products.length) {
      //slice products array to match page size before rerender to avoid table warning
      setProducts(products.slice(0, perPage))
    }
    window.scrollTo({ top: 0, behavior: 'auto' })
  }

  useEffect(() => {
    fetchSale()
  }, [])

  useEffect(() => {
    fetchSaleProducts()
  }, [currentPage, itemsPerPage])

  //
  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    let inputNode
    switch (dataIndex) {
      case 'discount_rate':
        inputNode = <InputNumber min={0} max={100} />
        break
      case 'quantity':
        inputNode = <InputNumber min={0} />
        break

      default:
        inputNode = inputType === 'number' ? <InputNumber /> : <Input />
        break
    }
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    )
  }

  const [form] = Form.useForm()
  const [editingKey, setEditingKey] = useState('')

  const isEditing = (record) => record.id === editingKey

  const edit = (record) => {
    form.setFieldsValue({
      quantity: '',
      discount: '',
      ...record,
    })
    setEditingKey(record.id)
  }
  const cancel = () => {
    setEditingKey('')
  }
  const save = async (saleProductId) => {
    try {
      const row = await form.validateFields()
      const newProducts = [...products]
      const index = newProducts.findIndex((item) => saleProductId === item.id)

      Api.put(`/api/v1/sales/${sale.id}/products/${saleProductId}`, row)
        .then((res) => {
          if (index > -1) {
            const item = newProducts[index]
            newProducts.splice(index, 1, {
              ...item,
              ...res.data,
            })
            setProducts(newProducts)
            setEditingKey('')
          } else {
            newProducts.push(row)
            setProducts(newProducts)
            setEditingKey('')
          }
        })
        .catch((err) => {
          console.error(err)
          if (err.response.data.errors) {
            alert(Object.values(err.response.data.errors)[0][0])
          }
        })
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo)
    }
  }

  const columns = [
    {
      title: '',
      align: 'center',
      width: 60,
      render: (item) =>
        !item.catalog_details?.thumbnail_url ? (
          'No Image'
        ) : (
          <Image
            src={item.catalog_details.thumbnail_url}
            style={{
              width: '40px',
              height: '40px',
              objectFit: 'contain',
            }}
          />
        ),
    },
    {
      title: 'SKU',
      render: (item) => item.catalog_details?.sku,
    },
    {
      title: 'Title',
      render: (item) => item.catalog_details?.title,
    },
    {
      title: () => (
        <>
          <span style={{ marginRight: 6 }}>Sale Quantity</span>
          <Tooltip
            placement='top'
            title='When setting this field to 0, the current inventory on the external platform will be used as quantity.'
          >
            <FaQuestionCircle style={{ color: '#aaa' }} />
          </Tooltip>
        </>
      ),
      dataIndex: 'quantity',
      editable: true,
    },
    {
      title: () => (
        <>
          <span style={{ marginRight: 6 }}>Discount</span>
          <Tooltip
            placement='top'
            title="This percentage will be subtracted from the product's retail price when a sale starts."
          >
            <FaQuestionCircle style={{ color: '#aaa' }} />
          </Tooltip>
        </>
      ),
      dataIndex: 'discount_rate',
      editable: true,
      render: (rate) => <>{rate}%</>,
    },
    {
      title: () => (
        <>
          <span style={{ marginRight: 6 }}>Prevent Loss</span>
          <Tooltip
            placement='top'
            title="When checked and a sale starts, the app won't allow the sale price to go below the Cost Price set on the external platform. If cost price is not set, the product will be skipped and not included in a sale."
          >
            <FaQuestionCircle style={{ color: '#aaa' }} />
          </Tooltip>
        </>
      ),
      render: (item) => (
        <input
          type='checkbox'
          checked={item.prevent_loss}
          onChange={(e) => updatePreventLoss(e, item)}
          disabled={sale && !['inactive', 'ended'].includes(sale.status)}
        ></input>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (_, record) => {
        const editable = isEditing(record)
        if (sale.status === 'ongoing')
          return (
            <>
              <div>
                <Popconfirm
                  title='Product will be deleted from current sale. Continue?'
                  onConfirm={() => deleteSaleProduct(record)}
                >
                  <span style={{ color: 'red', cursor: 'pointer' }}>
                    Delete from sale
                  </span>
                </Popconfirm>
              </div>
              {record.notes && (
                <span
                  style={{
                    backgroundColor: 'red',
                    color: 'white',
                  }}
                >
                  {record.notes}
                </span>
              )}
            </>
          )

        if (['starting', 'closing'].includes(sale.status))
          return (
            <div style={{ textTransform: 'capitalize' }}>{sale.status}</div>
          )
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.id)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Typography.Link>
            <Popconfirm title='Sure to cancel?' onConfirm={cancel}>
              <span style={{ color: 'red' }}>Cancel</span>
            </Popconfirm>
          </span>
        ) : (
          <>
            <Typography.Link
              disabled={editingKey !== ''}
              onClick={() => edit(record)}
              style={{ marginRight: 6 }}
            >
              Edit
            </Typography.Link>
            <Popconfirm
              title='Sure to delete?'
              onConfirm={() => deleteSaleProduct(record)}
            >
              <span style={{ color: 'red', cursor: 'pointer' }}>Delete</span>
            </Popconfirm>
          </>
        )
      },
    },
  ]

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: 'number',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    }
  })

  const uploadProps = {
    name: 'file',
    accept: '.csv,.txt,.xlsx',
    maxCount: 1,
    customRequest: function ({ file, onSuccess, onError }) {
      let fd = new FormData()
      fd.append('file', file)
      Api.post(`/api/v1/sales/${id}/products/import`, fd)
        .then(() => {
          onSuccess('OK')
          message.success('Sale Import is now Processing...')
        })
        .catch((err) => {
          onError(err)
          message.error('Error uploading file.')
        })
    },
  }

  return (
    <div>
      {sale && (
        <div>
          <h2>{sale.description}</h2>

          {['inactive', 'ended'].includes(sale.status) && (
            <Flex justifyContent='space-between' style={{ marginBottom: 12 }}>
              <FlexItem>
                <Button
                  color={addingProducts ? 'red' : 'green'}
                  text={addingProducts ? 'Stop' : 'Add Products'}
                  onClick={() => setAddingProducts(!addingProducts)}
                  style={{ marginRight: 8 }}
                ></Button>
                <Upload {...uploadProps}>
                  <Button
                    icon={<FaUpload />}
                    color='#1ea59d'
                    text='Import Products'
                    disabled={addingProducts}
                  ></Button>
                </Upload>
              </FlexItem>
              <FlexItem>
                <Button
                  text='Refresh'
                  onClick={() => fetchSaleProducts()}
                ></Button>
              </FlexItem>
            </Flex>
          )}
          <Flex flexGap={10}>
            {addingProducts && (
              <FlexItem flex='50%'>
                <ProductsTable
                  disableQuery
                  perPage={10}
                  selectedActionText='Add to sale'
                  selectedActionOnClick={(items) => addProductsToSale(items)}
                />
              </FlexItem>
            )}
            <FlexItem flex='50%'>
              <div style={{ marginBottom: 10 }}>
                <Search
                  placeholder='Search Sale Products...'
                  allowClear
                  onChange={(e) => setKeywords(e.target.value)}
                  onSearch={fetchSaleProducts}
                />
              </div>
              <Form form={form} component={false}>
                {products.length > 0 && (
                  <Table
                    rowKey='id'
                    dataSource={products}
                    bordered
                    components={{
                      body: {
                        cell: EditableCell,
                      },
                    }}
                    columns={mergedColumns}
                    loading={productsLoading}
                    rowClassName='editable-row'
                    pagination={{
                      defaultPageSize: 10,
                      pageSize: itemsPerPage,
                      total: totalItems,
                      pageSizeOptions: [10, 15, 25, 50, 100],
                      onChange: handlePaginationChange,
                    }}
                  />
                )}
              </Form>
            </FlexItem>
          </Flex>
        </div>
      )}
    </div>
  )
}

export default SaleProducts
