import React from 'react'
import { Navigate } from 'react-router-dom'

const RequireGuest = ({ children }) => {
  const authenticated = localStorage.getItem('is_user_logged_in') === 'true'

  return authenticated === false ? children : <Navigate to='/' replace />
}

export default RequireGuest
