import React from 'react'
import { Navigate } from 'react-router-dom'

const RequireAuth = ({ children }) => {
  const authenticated = localStorage.getItem('is_user_logged_in') === 'true'

  return authenticated === true ? children : <Navigate to='/login' replace />
}

export default RequireAuth
