import React from 'react'
import { Input, InputNumber, Form, Button, Checkbox } from 'antd'
import { useNavigate } from 'react-router'
import Api from '../services/Api'

const NewSaleForm = () => {
  const navigate = useNavigate()

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  }
  /* eslint-disable no-template-curly-in-string */

  const validateMessages = {
    required: '${label} is required!',
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  }

  const onFinish = async (data) => {
    await Api.post('/api/v1/sales', {
      description: data.description,
      discount_rate: data.discount_rate,
      prevent_loss: data.prevent_loss,
    })

    navigate('/')
  }

  return (
    <div>
      <Form
        {...layout}
        name='nest-messages'
        onFinish={onFinish}
        validateMessages={validateMessages}
        initialValues={{ prevent_loss: true }}
      >
        <Form.Item
          name='description'
          label='Description'
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='discount_rate'
          label='Discount Rate'
          rules={[
            {
              required: true,
              type: 'number',
              min: 0,
              max: 100,
            },
          ]}
        >
          <InputNumber min={0} max={100} />
        </Form.Item>
        <Form.Item
          name='prevent_loss'
          label='Prevent Loss'
          valuePropName='checked'
        >
          <Checkbox onChange={(e) => e.target.checked} />
        </Form.Item>

        <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
          <Button type='primary' htmlType='submit'>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default NewSaleForm
