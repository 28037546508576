import React from 'react'
import NewSaleForm from '../components/NewSaleForm'

const CreateSale = () => {
  return (
    <div>
      <NewSaleForm />
    </div>
  )
}

export default CreateSale
