import { Card, Tag } from 'antd'
import React from 'react'
import { CloseCircleOutlined } from '@ant-design/icons'

const Notification = ({ children, notification }) => {
  return (
    <Card>
      <div>
        <small>
          {new Date(notification.created_at).toLocaleString('ja-JP')}
        </small>
      </div>
      {notification.type ===
        'App\\Notifications\\ImportHasFailedNotification' && (
        <Tag icon={<CloseCircleOutlined />} color='error'>
          Error
        </Tag>
      )}

      {children}
      {notification.data.message}
    </Card>
  )
}

export default Notification
