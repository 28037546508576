import { Routes, Route } from 'react-router-dom'
import Logout from './components/auth/Logout'
import RequireAuth from './components/auth/RequireAuth'
import RequireGuest from './components/auth/RequireGuest'
import LayoutComponent from './layouts/Layout'
import CreateSale from './pages/CreateSale'
import Home from './pages/Home'
import Login from './pages/Login'
import Notifications from './pages/Notifications'
import Products from './pages/Products'
import SaleProducts from './pages/SaleProducts'

function App() {
  return (
    <div className='App'>
      <Routes>
        <Route
          exact
          path='/login'
          element={
            <RequireGuest>
              <Login />
            </RequireGuest>
          }
        />
        <Route
          exact
          path='/logout'
          element={
            <RequireAuth>
              <Logout />
            </RequireAuth>
          }
        />
        <Route
          path='/'
          element={
            <RequireAuth>
              <LayoutComponent />
            </RequireAuth>
          }
        >
          <Route
            path='/'
            element={
              <RequireAuth>
                <Home />
              </RequireAuth>
            }
          ></Route>
          <Route
            path='/products'
            element={
              <RequireAuth>
                <Products />
              </RequireAuth>
            }
          ></Route>
          <Route
            path='/sales/:id/products'
            element={
              <RequireAuth>
                <SaleProducts />
              </RequireAuth>
            }
          ></Route>
          <Route
            path='/sales/create'
            element={
              <RequireAuth>
                <CreateSale />
              </RequireAuth>
            }
          ></Route>
          <Route
            path='/notifications'
            element={
              <RequireAuth>
                <Notifications />
              </RequireAuth>
            }
          ></Route>
        </Route>
      </Routes>
    </div>
  )
}

export default App
