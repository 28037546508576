import React from 'react'
import { Button, message } from 'antd'
import ProductsTable from '../components/ProductsTable'
import Api from '../services/Api'
import Flex from '../components/common/Flex'

const Products = () => {
  const syncProducts = async () => {
    message.info('Product syncing has started.')
    await Api.post('api/v1/products/sync')
  }

  return (
    <div>
      <Flex justifyContent='space-between' style={{ marginBottom: 8 }}>
        <h2>Products</h2>
        <Button type='primary' onClick={syncProducts}>
          Sync Products
        </Button>
      </Flex>

      <ProductsTable />
    </div>
  )
}

export default Products
