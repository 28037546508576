import React from 'react'
import { Outlet, Link, useLocation } from 'react-router-dom'
import { Layout, Menu } from 'antd'
const { Header, Content } = Layout

const LayoutComponent = () => {
  const location = useLocation()

  return (
    <Layout>
      <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
        <Menu
          theme='dark'
          mode='horizontal'
          defaultSelectedKeys={[location.pathname]}
        >
          <Menu.Item key='/'>
            <span>Home</span>
            <Link to='/'></Link>
          </Menu.Item>
          <Menu.Item key='/products'>
            <span>Products</span>
            <Link to='/products'></Link>
          </Menu.Item>
          <Menu.Item key='/notifications'>
            <span>Notifications</span>
            <Link to='/notifications'></Link>
          </Menu.Item>
          <Menu.Item key='/logout'>
            <span>Logout</span>
            <Link to='/logout'></Link>
          </Menu.Item>
        </Menu>
      </Header>
      <Content
        className='site-layout'
        style={{ padding: '0 50px', marginTop: 64 }}
      >
        <div className='site-layout-background' style={{ padding: '24px 0' }}>
          <Outlet />
        </div>
      </Content>
    </Layout>
  )
}

export default LayoutComponent
